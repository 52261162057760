import React from 'react'
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
// import { getAnalytics } from 'firebase/analytics'
import { getFirebaseConfig } from '../lib'
import Receipt from './receipt.js'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase
const app = initializeApp(getFirebaseConfig())
// const analytics = getAnalytics(app) // eslint-disable-line no-unused-vars
class PaymentReceipt extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      amount: undefined,
      isSubmitting: false,
      alertMessage: '',
      merchantId: undefined,
      orderId: undefined,
      accountName: '',
      accountPhone: '',
      accountEmail: '',
      accountStreet: '',
      accountCity: '',
      accountState: '',
      accountZip: '',
      accountImage: '',
      complete: false
    }
    this.setState = this.setState.bind(this)
    // this.handleSubmit = this.handleSubmit.bind(this)
    // this.finishSubmit = this.finishSubmit.bind(this)
    // this.loadCollect = this.loadCollect.bind(this)
    // this.handleEmail = this.handleEmail.bind(this)
    // console.log('analytics', analytics)
    
    // console.log('functions', functions)
    // console.log('host', window.location.protocol + '//' + window.location.host)
    // console.log('path', window.location.pathname)
    

  }

  async componentDidMount() {
    const functions = getFunctions(app)
    // const getTokenizationKey = httpsCallable(functions, 'getTokenizationKey')
    const loadSignedReceipt = httpsCallable(functions, 'onCall-loadSignedReceipt')
    // this is a public key
    try {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const {
        mId: merchantId,
        refId,
        date: datetime,
        exp: expire,
        sig: signature,
      } = Object.fromEntries(urlSearchParams.entries())

      const result = await loadSignedReceipt({ merchantId, refId, datetime, expire, signature })
      const {
        accountName,
        accountPhone,
        accountEmail,
        accountStreet,
        accountCity,
        accountState,
        accountZip,
        accountImage,
        firstName,
        lastName,
        address,
        city,
        state,
        zip,
        orderId,
        amountPaid,
        process,
        paymentType,
        last4,
        cardType,
        authCode,
        avsResponse,
        invoiceUrl,
        termsAndConditions,
        capture_signature,
        surchargeFee,
        surchargeMessage,
        canClosePage,
        paymentDate,
      } = result.data
      this.setState({
        merchantId,
        refId,
        datetime, 
        expire, 
        signature,
        accountName,
        accountPhone,
        accountEmail,
        accountStreet,
        accountCity,
        accountState,
        accountZip,
        accountImage,
        firstName,
        lastName,
        address,
        city,
        state,
        zip,
        orderId,
        amountPaid,
        process,
        paymentType,
        last4,
        cardType,
        authCode,
        avsResponse,
        invoiceUrl,
        termsAndConditions,
        capture_signature,
        surchargeFee,
        surchargeMessage,
        canClosePage,
        paymentDate,
        complete: true
      })
     
    } catch (err) {
      console.log('error: ', err.message)
      this.setState({ isSubmitting: false, alertMessage: err.message, alertClass: 'alert alert-danger' })
    }
  }

  render() {
    if (this.state.complete) { // eslint-disable-line no-constant-condition
      return (
        <div>
          { this.state.alertMessage && (
            <div className={this.state.alertClass} role="alert">
              {this.state.alertMessage}
            </div>
          )}
          { this.state.isSubmitting && (
            <div id="overlay">
              <div className="spinner"></div>
            </div>
          )}
          <Receipt {...this.state}/>
        </div>
      )
    }
    if (!this.state.complete) {
      return (
        <div className="App">
          { this.state.alertMessage ? (
          <div className={this.state.alertClass} role="alert">
            {this.state.alertMessage}
          </div>
        ) : (
          <div className="text-center">
            <div className="spinner-border m-5" style={{ width: 5 + 'rem', height: 5 + 'rem' }} role="status">
            </div>
          </div>
        )}
        </div>
      )
    }
  }
}

export default PaymentReceipt